* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}
.link:hover {
  background: lightskyblue;
  color: #000;
  transition: all 0.5s;
}
.active {
  background: lightskyblue;
  color: #000;
}

.MuiDataGrid-root .MuiDataGrid-cell{
  /* color: tomato; */
  white-space: normal !important;
  word-wrap: break-word !important;
  }
